import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import ElementInstance from "components/survey-step-elements/element-instance"
import inflection from "inflection"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import SurveyInstance from "components/surveys/survey-instance"
import {SurveyStepElement} from "models"

const partialElementTypesRequireContext = require.context("./partial-types", true, /\.jsx$/)

export default memo(shapeComponent(class ComponentsSurveyStepElementsPartial extends ShapeComponent {
  static propTypes = propTypesExact({
    className: PropTypes.string,
    element: PropTypes.instanceOf(SurveyStepElement).isRequired,
    surveyInstance: PropTypes.instanceOf(SurveyInstance).isRequired
  })

  setup() {
    this.elementIndex = useMemo(() => this.p.surveyInstance.getElementIndex(), [])
    this.elementInstance = useMemo(() => new ElementInstance(), [])
    this.teamSurveyStepElement = useMemo(() => this.findTeamSurveyElement(), [this.p.element.id()])
  }

  render() {
    const {elementIndex, elementInstance, teamSurveyStepElement} = this.tt
    const {className, element, surveyInstance} = this.props
    const requireNames = [
      `./${inflection.dasherize(element.elementType())}.jsx`,
      `./${inflection.dasherize(element.elementType())}/index.jsx`
    ]
    let PartialElementType

    for (const requireName of requireNames) {
      try {
        PartialElementType = partialElementTypesRequireContext(requireName).default
      } catch (error) {
        // Ignore - handled later.
      }
    }

    if (!PartialElementType) {
      throw new Error(`Couldn't require any of '${requireNames.join(", ")}' from ${partialElementTypesRequireContext.keys()}`)
    }

    elementInstance.set({element, elementIndex, teamSurveyStepElement})
    elementInstance.setFromSurveyInstance(surveyInstance)

    if (!teamSurveyStepElement) throw new Error("No 'teamSurveyStepElement' could be found in 'ComponentsSurveyStepElementsPartial'")
    if (!PartialElementType) throw new Error(`Couldn't find partial for ${element.elementType()}`)

    return (
      <div className={classNames("components--survey-steps-elements--partial", className)} data-element-id={element.id()}>
        <input
          name={`team_survey_step[team_survey_step_elements_attributes][${elementIndex}][id]`}
          type="hidden"
          value={teamSurveyStepElement.id()}
        />
        {teamSurveyStepElement.state() == "new" &&
          <input
            name={`team_survey_step[team_survey_step_elements_attributes][${elementIndex}][state]`}
            type="hidden"
            value="completed"
          />
        }
        <PartialElementType
          className={classNames("components--survey-step-elements--partial", className)}
          elementInstance={elementInstance}
        />
      </div>
    )
  }

  findTeamSurveyElement() {
    const {element, surveyInstance} = this.p
    const {teamSurveyStep} = surveyInstance.data

    if (teamSurveyStep.isNewRecord()) throw new Error("'teamSurveyStep' should always be persisted")

    const teamSurveyStepElement = teamSurveyStep
      .teamSurveyStepElements().loaded()
      .find((teamSurveyStepElement) => teamSurveyStepElement.surveyStepElementId() == element.id())

    if (!teamSurveyStepElement) {
      const teamSurveyStepElementIds = teamSurveyStep.teamSurveyStepElements().loaded().map((teamSurveyStepElement) =>
        teamSurveyStepElement.surveyStepElementId()
      )

      throw new Error(
        `Couldn't find 'teamSurveyStepElement' in 'ComponentsSurveyStepsElements' for element ${element.id()} in: ${teamSurveyStepElementIds.join(", ")}`
      )
    }

    return teamSurveyStepElement
  }
}))
