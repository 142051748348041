import React, {useMemo} from "react"
import {shapeComponent, ShapeComponent} from "set-state-compare/src/shape-component"
import {TeamSurvey, User} from "models"
import LayoutBase from "components/layout/base"
import memo from "set-state-compare/src/memo"
import setLayout from "shared/set-layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"

export default memo(shapeComponent(class RoutesPlaySchoolClassCheckInsTeamSurveysCompleted extends ShapeComponent {
  setup() {
    setLayout("play")

    const {t} = useI18n({namespace: "js.routes.play.school_class_check_ins.team_surveys.completed"})
    const {teamSurvey, teamSurveyId} = useModel(TeamSurvey, {
      match: this.p.match,
      preload: ["team.school_class"],
      select: {
        SchoolClass: ["id", "schoolId"],
        Team: ["id"],
        TeamSurvey: ["id", "state"]
      }
    })

    this.setInstance({t, teamSurvey, teamSurveyId})
    this.useStates({completed: false})

    useMemo(() => {
      if (teamSurvey) {
        this.completeTeamSurvey()
      }
    }, [teamSurvey?.id()])
  }

  async completeTeamSurvey() {
    const {teamSurvey} = this.tt

    if (teamSurvey.state() != "completed") {
      await User.stateEvent({model: teamSurvey, state_event: "complete"})
    }

    this.setState({completed: true})
  }

  render() {
    const {t, teamSurvey} = this.tt
    const {completed} = this.s

    return (
      <LayoutBase dataSet={{route: "play/school-class-check-in/team-surveys/completed"}} layout="play" schoolClass={teamSurvey?.team()?.schoolClass()}>
        {completed &&
          <div className="completed-content-container">
            <div className="completed-content">
              <div style={{textAlign: "center"}}>
                <div style={{marginBottom: "25px"}}>
                  {t(".you_are_done")}
                </div>
                <div>
                  {t(".thank_you_for_participating")}
                </div>
              </div>
            </div>
          </div>
        }
      </LayoutBase>
    )
  }
}))
