import React, {useEffect, useMemo, useRef} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import CkeditorPresentation from "ckeditor/presentation"
import {ContentAbsoluteContainer} from "models"
import {digg} from "diggerize"
import ElementInstance from "components/survey-step-elements/element-instance"
import inflection from "inflection"
import Link from "@kaspernj/api-maker/build/link"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import RenderHtml from "shared/render-html"
import Routes from "shared/routes"
import SurveyStepElementsPartial from "components/survey-step-elements/partial"
import {Text} from "shared/base"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useResizeObserver from "@kaspernj/api-maker/build/use-resize-observer"
import {View} from "react-native"

const partialElementTypesRequireContext = require.context("components/survey-step-elements/partial-types", true, /\.jsx$/)

const ComponentsContentsPresentationAbsoluteContainer = memo(shapeComponent(class PresentationAbsoluteContainer extends ShapeComponent {
  static defaultProps = {
    editSurveyStepElements: false,
    elementInstance: null,
    reactNative: false
  }

  static propTypes = propTypesExact({
    absoluteContainer: PropTypes.instanceOf(ContentAbsoluteContainer).isRequired,
    editSurveyStepElements: PropTypes.bool.isRequired,
    elementInstance: PropTypes.instanceOf(ElementInstance),
    nestedAbsoluteContainers: PropTypes.object.isRequired,
    parsedBody: PropTypes.object.isRequired,
    reactNative: PropTypes.bool.isRequired
  })

  setup() {
    const {t} = useI18n({namespace: "js.components.contents.presentation.absolute_container"})

    this.newElement = useMemo(() => this.spawnNewElementInstance(), [this.p.absoluteContainer.surveyStepElement()?.id()])
    this.rootRef = useRef()
    this.t = t
    this.useStates({
      absoluteContainers: () => this.initialAbsoluteContainers(),
      height: "5px"
    })
    useEffect(() => {
      this.setAbsoluteContainerHeight()
    }, [])
    useResizeObserver(this.rootRef.current, this.tt.onAbsoluteContainerResize)
  }

  spawnNewElementInstance() {
    const {absoluteContainer, editSurveyStepElements, elementInstance} = this.p
    const surveyStep = elementInstance?.data?.surveyStep
    let element

    if (!absoluteContainer.surveyStepElement()) {
      return null
    } else if (surveyStep) {
      element = surveyStep.elements().loaded().find((element) => element.id() == absoluteContainer.surveyStepElement().id())

      if (!element) {
        throw new Error("Couldn't find element in given step")
      }
    } else {
      if (!editSurveyStepElements) throw new Error("Step was required for ContentsPresentationAbsoluteContainer but wasn't given")

      element = absoluteContainer.surveyStepElement()
    }

    return element
  }

  initialAbsoluteContainers() {
    const {absoluteContainer, nestedAbsoluteContainers} = this.p

    if (absoluteContainer.isNewRecord()) return []

    return nestedAbsoluteContainers[absoluteContainer.id()]?.filter((absoluteContainer) => absoluteContainer.liquidConditionsResult()) || []
  }

  render() {
    const {rootRef, t} = this.tt
    const {absoluteContainer, editSurveyStepElements, nestedAbsoluteContainers, parsedBody, reactNative} = this.p
    const {elementInstance} = this.props
    const {absoluteContainers, height} = this.s
    const html = digg(parsedBody, absoluteContainer.id())
    const style = {}
    let PartialElementType, surveyStepElement

    if (absoluteContainer.surveyStepElement()) {
      surveyStepElement = this.tt.newElement
    }

    if (surveyStepElement) {
      const requireNames = [
        `./${inflection.dasherize(surveyStepElement.elementType())}.jsx`,
        `./${inflection.dasherize(surveyStepElement.elementType())}/index.jsx`
      ]

      for (const requireName of requireNames) {
        try {
          PartialElementType = partialElementTypesRequireContext(requireName).default
          break
        } catch (error) {
          // Ignore because it might be under a different name
        }
      }

      if (!PartialElementType) {
        throw new Error(`Couldn't require partial for ${surveyStepElement.elementType()} from: ${partialElementTypesRequireContext.keys().join(", ")}`)
      }
    }

    if (absoluteContainer.hasBackgroundImageUrl()) {
      style.backgroundImage = `url('${absoluteContainer.backgroundImageUrl()}')`
    }

    if (absoluteContainer.minimumHeight()) {
      style.minHeight = height
    } else {
      style.height = height
    }

    if (!absoluteContainer.responsive()) {
      if (absoluteContainer.minimumWidth()) {
        style.minWidth = `${absoluteContainer.widthPercent()}%`
      } else {
        style.width = `${absoluteContainer.widthPercent()}%`
      }

      style.left = `${absoluteContainer.leftPercent()}%`
      style.top = `${absoluteContainer.topPercent()}%`
    }

    if (absoluteContainer.styling()?.styling()) {
      for (const key in absoluteContainer.styling().styling()) {
        const value = absoluteContainer.styling().styling()[key]

        style[key] = value
      }
    }

    return (
      <div
        className="components--contents--presentation--absolute-container"
        data-absolute-container-id={absoluteContainer.id()}
        data-responsive={absoluteContainer.responsive()}
        ref={rootRef}
        style={style}
      >
        <div
          className="absolute-container-inner"
          data-horizontal-align={absoluteContainer.horizontalAlign()}
          data-vertical-align={absoluteContainer.verticalAlign()}
        >
          {absoluteContainers.map((subAbsoluteContainer) =>
            <ComponentsContentsPresentationAbsoluteContainer
              absoluteContainer={subAbsoluteContainer}
              editSurveyStepElements={editSurveyStepElements}
              elementInstance={elementInstance}
              key={subAbsoluteContainer.id()}
              nestedAbsoluteContainers={nestedAbsoluteContainers}
              parsedBody={parsedBody}
              reactNative={reactNative}
            />
          )}
          {surveyStepElement && editSurveyStepElements &&
            <View>
              <Text style={{marginBottom: 10}}>
                {surveyStepElement.translatedElementType()}
              </Text>
              <Link className="edit-nested-element-link" to={Routes.adminSurveyStepElementPath(surveyStepElement.id())}>
                {t(".edit_element")}
              </Link>
            </View>
          }
          {surveyStepElement && !editSurveyStepElements &&
            <SurveyStepElementsPartial
              className="components--contents--presentation--absolute-container--partial"
              element={surveyStepElement}
              surveyInstance={elementInstance.surveyInstance}
            />
          }
          {reactNative && !surveyStepElement &&
            <RenderHtml source={html} />
          }
          {!reactNative && !surveyStepElement &&
            <CkeditorPresentation className="absolute-container-body" html={html} />
          }
        </div>
      </div>
    )
  }

  onAbsoluteContainerResize = () => this.setAbsoluteContainerHeight()

  setAbsoluteContainerHeight() {
    const {absoluteContainer} = this.p
    const element = digg(this.tt.rootRef, "current")
    const currentWidth = element?.offsetWidth
    const height = (absoluteContainer.heightPixels() / absoluteContainer.widthPixels()) * currentWidth

    this.setState({height: `${height}px`})
  }
}))

export default ComponentsContentsPresentationAbsoluteContainer
