import {useEffect} from "react"
const useHiddenTawkWidget = () => {
  useEffect(() => {
    if (window.Tawk_API?.hideWidget == "function") {
      window.Tawk_API?.hideWidget()
    }

    return () => {
      if (window.Tawk_API?.showWidget == "function") {
        window.Tawk_API?.showWidget()
      }
    }
  }, [])
}

export default useHiddenTawkWidget
