import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import memo from "set-state-compare/src/memo"
import React from "react"
import {Text} from "shared/base"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useQueryParams from "on-location-changed/build/use-query-params"
import UtilsCard from "components/utils/card"
import {View} from "react-native"
const styles = StyleSheet.create({
  rootView: {
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%"
  },
  card: {maxWidth: 600}
})

export default memo(shapeComponent(class RoutesPlaySchoolClassCheckInsNotFound extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.play.school_class_check_ins.not_found"})
    const queryParams = useQueryParams()

    this.code = queryParams.code
    this.t = t
  }

  render() {
    const {code, t} = this.tt

    return (
      <View dataSet={{route: "play/school-class-check-ins/not-found"}} style={styles.rootView}>
        <UtilsCard style={styles.card}>
          <Text>{t(".no_woof_found_with_that_code", {code})}</Text>
          <Text>{t(".maybe_it_has_been_closed")}</Text>
        </UtilsCard>
      </View>
    )
  }
}))
