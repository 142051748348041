import "./build/translations/da.js"
import "./build/translations/no.js"
import "./build/translations/sv.js"
import React, {useEffect, useRef} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import CKEditor from "./build/ckeditor"
import EventEmitter from "events"
import I18n from "shared/i18n"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import UploadAdapterPlugin from "./upload-adapter-plugin"
import useEventEmitter from "@kaspernj/api-maker/build/use-event-emitter"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"

export default memo(shapeComponent(class CkeditorEditor extends ShapeComponent {
  static propTypes = propTypesExact({
    events: PropTypes.instanceOf(EventEmitter),
    defaultValue: PropTypes.string,
    id: PropTypes.string,
    inputRef: PropTypes.object,
    name: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    resource: PropTypes.object,
    resourceId: PropTypes.string,
    resourceType: PropTypes.string
  })

  setup() {
    const backupInputRef = useRef()
    const {t} = useI18n({namespace: "js.ckeditor.editor"})

    this.editorRef = this.props.inputRef || backupInputRef
    this.t = t

    useEventEmitter(this.props.events, "clear", this.tt.onClearEvent)

    useEffect(() => {
      this.initializeEditor()
    }, [])
  }

  render() {
    if (!this.props.resource && (!this.props.resourceId || !this.props.resourceType)) {
      throw new Error(`Either 'resource' or 'resourceId' and 'resourceType' is required for ID '${this.props.id}' name '${this.props.name}'`)
    }

    const {defaultValue, id, name, placeholder, resource, resourceType} = this.props

    return (
      <div className="ckeditor--editor ckeditor-content" data-input-id={id}>
        <div data-class="components--ckeditor--presentation">
          <textarea
            data-resource-id={this.resourceId()}
            data-resource-type={resource?.modelClassData()?.name || resourceType}
            defaultValue={defaultValue}
            id={id}
            name={name}
            placeholder={placeholder}
            ref={this.tt.editorRef}
          />
        </div>
      </div>
    )
  }

  headingHash = (level) => ({
    model: `heading${level}`,
    view: {
      name: "div",
      classes: `ckeditor-heading-${level}`
    },
    title: this.t(".heading_with_number", {number: level}),
    class: `ck-heading_heading${level}`,

    // It needs to be converted before the standard 'heading2'.
    converterPriority: "high"
  })

  onClearEvent = () => this.tt.editor.setData("")
  onCkeditorChanged = () => {
    this.tt.editor.updateSourceElement()

    if (!this.tt.editorRef.current) {
      // The editor might have been unmounted - doesn't make sense to trigger event without the value
      return
    }

    if (this.props.onChange) this.p.onChange({value: this.tt.editorRef.current.value})
  }

  resourceId = () => {
    const actualResourceId = this.props.resource?.id() || this.props.resourceId

    if (!actualResourceId) {
      throw new Error("No resource ID was given which breaks uploading of images")
    }

    return actualResourceId
  }

  initializeEditor = async () => {
    let language = I18n.locale

    // Swedish is called something else for CKEditor than in Rails
    if (language == "se") language = "sv"

    this.editor = await this.spawnEditor(language)

    if (this.props.defaultValue) this.editor.setData(this.props.defaultValue)

    this.editor.model.document.on("change:data", this.tt.onCkeditorChanged)
  }

  spawnEditor(language) {
    return new Promise((resolve, reject) => {
      CKEditor
        .create(this.tt.editorRef.current, {
          entities: false,
          extraPlugins: [UploadAdapterPlugin],
          heading: {
            options: [
              {model: "paragraph", title: "Paragraph", class: "ck-heading_paragraph"},
              this.headingHash(1),
              this.headingHash(2),
              this.headingHash(3),
              this.headingHash(4),
              this.headingHash(5),
              this.headingHash(6),
              this.headingHash(7),
              this.headingHash(8)
            ]
          },
          language,
          typing: {
            transformations: {
              include: [] // Fixes CKEditor replaces quotes
            }
          }
        })
        .then((editor) => {
          resolve(editor)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}))
