import Checkbox from "@kaspernj/api-maker/build/inputs/checkbox"
import classNames from "classnames"
import InputContainer from "components/inputs/input-container"
import memo from "set-state-compare/src/memo"
import React from "react"
import useInput from "@kaspernj/api-maker/build/use-input"

const ComponentsInputsCheckbox = memo((props) => {
  const {inputProps, restProps} = useInput({props, wrapperOptions: {type: "checkbox"}})
  const {className, hint, labelStyle, ...actualRestProps} = restProps
  const {ref, type, ...restInputProps} = inputProps

  return (
    <div className={classNames("components--inputs--checkbox", className)}>
      <Checkbox inputRef={ref} {...restInputProps} {...actualRestProps} type="checkbox" />
      <label htmlFor={inputProps.id}>
        <div className="fake-checkbox">
          <i className="check-icon fa fa-fw fa-check" />
        </div>
      </label>
    </div>
  )
})

const CheckboxWithInputContainer = memo((props) => {
  const {inputProps, restProps, wrapperOpts} = useInput({props, wrapperOptions: {type: "checkbox"}})

  return (
    <InputContainer hint={restProps.hint} inputProps={inputProps} labelStyle={restProps.labelStyle} wrapperOpts={wrapperOpts}>
      <ComponentsInputsCheckbox {...props} />
    </InputContainer>
  )
})

export {ComponentsInputsCheckbox as Checkbox}
export default CheckboxWithInputContainer
