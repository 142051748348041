import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Card from "components/card"
import classNames from "classnames"
import ElementInstance from "components/survey-step-elements/element-instance"
import memo from "set-state-compare/src/memo"
import MoodBarChart from "components/score-factor-groups/mood-bar-chart"
import PropTypes from "prop-types"
import React from "react"

export default memo(shapeComponent(class ComponentsSurveyStepElementsPartialTypesMoodBarChart extends ShapeComponent {
  static propTypes = {
    className: PropTypes.string,
    elementInstance: PropTypes.instanceOf(ElementInstance).isRequired
  }

  render() {
    const {className, elementInstance} = this.props
    const {schoolClass} = elementInstance.data

    return (
      <div className={classNames("components--survey-step-elements--partial-types--mood-bar-chart", className)}>
        <Card>
          <div style={{paddingTop: "30px", paddingRight: "60px"}}>
            <MoodBarChart schoolClass={schoolClass} />
          </div>
        </Card>
      </div>
    )
  }
}))
