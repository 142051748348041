import {SchoolClassCheckIn, Survey, TeamSurvey, TeamSurveyStep} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AppHistory from "shared/history"
import memo from "set-state-compare/src/memo"
import React from "react"
import RoutesPlay from "shared/routes-play"
import setLayout from "shared/set-layout"
import SurveysLayout from "components/surveys/layout"
import SurveysSelectedAttributes from "components/surveys/selected-attributes"
import SurveyStepsPresentation from "components/survey-steps/presentation"
import useHiddenTawkWidget from "shared/use-hidden-tawk-widget"
import useModel from "@kaspernj/api-maker/build/use-model"

const teamSurveyStepPreloads = ["team_survey_step_elements.answers"]
const teamSurveyStepSelects = Object.assign({}, SurveysSelectedAttributes.select)

teamSurveyStepSelects["TeamSurveyStep"] = ["id", "teamSurveyId"]
teamSurveyStepSelects["TeamSurveyStepElement"] = ["id", "parsedContent", "parsedMainContent", "state", "surveyStepElementId"]
teamSurveyStepSelects["TeamSurveyStepElementAnswer"] = ["id", "surveyStepElementOptionId"]

for (const surveyStepPreload of SurveysSelectedAttributes.preload) {
  teamSurveyStepPreloads.push(
    `survey_step.${surveyStepPreload}`
  )
}

export default memo(shapeComponent(class RoutesPlaySchoolClassCheckInsTeamSurveysSurveyStepsShow extends ShapeComponent {
  setup() {
    setLayout("play")

    const {teamSurvey, teamSurveyId} = useModel(TeamSurvey, {
      match: this.p.match,
      select: {
        TeamSurvey: ["id"]
      }
    })
    const {teamSurveyStep, teamSurveyStepId} = useModel(TeamSurveyStep, {
      match: this.p.match,
      preload: teamSurveyStepPreloads,
      select: teamSurveyStepSelects
    })
    const {schoolClassCheckIn, schoolClassCheckInId} = useModel(SchoolClassCheckIn, {
      match: this.p.match,
      preload: ["school_class"],
      select: {
        SchoolClass: ["id", "schoolId"],
        SchoolClassCheckIn: ["id"]
      }
    })

    this.setInstance({teamSurvey, teamSurveyId, teamSurveyStep, teamSurveyStepId, schoolClassCheckIn, schoolClassCheckInId})

    useHiddenTawkWidget()
  }

  render() {
    const {onAnswersSubmitted, schoolClassCheckIn, teamSurvey, teamSurveyStep} = this.tt
    const schoolClass = schoolClassCheckIn?.schoolClass()
    const surveyStep = teamSurveyStep?.surveyStep()

    if (schoolClassCheckIn && !schoolClass) {
      throw new Error(`Couldn't load school class for school class check in ${schoolClassCheckIn.id()}`)
    }

    return (
      <SurveysLayout
        dataSet={{
          route: "play/school-class-check-in/team-surveys/survey-steps/show",
          surveyStepId: surveyStep?.id(),
          teamSurveyStepId: teamSurveyStep?.id()
        }}
        headTitle={Survey.modelName().human()}
        layout="play"
        schoolClass={schoolClass}
      >
        {schoolClass && schoolClassCheckIn && teamSurveyStep &&
          <SurveyStepsPresentation
            onAnswersSubmitted={onAnswersSubmitted}
            schoolClass={schoolClass}
            surveyStep={surveyStep}
            teamSurvey={teamSurvey}
            teamSurveyStep={teamSurveyStep}
          />
        }
      </SurveysLayout>
    )
  }

  onAnswersSubmitted = ({nextTeamSurveyStep}) => {
    const {schoolClassCheckInId, teamSurvey} = this.tt

    if (nextTeamSurveyStep) {
      if (!nextTeamSurveyStep.id()) throw new Error("No ID on nextTeamSurveyStep")

      AppHistory.push(RoutesPlay.schoolClassCheckInTeamSurveyTeamSurveyStepPath(schoolClassCheckInId, teamSurvey.id(), nextTeamSurveyStep.id()))
    } else {
      AppHistory.push(RoutesPlay.schoolClassCheckInTeamSurveyCompletedPath(schoolClassCheckInId, teamSurvey.id()))
    }
  }
}))
