import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import memo from "set-state-compare/src/memo"
import React from "react"
import SchoolClassCheckInsCodeHandler from "components/school-class-check-ins/code-handler"
import SurveysLayout from "components/surveys/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"

export default memo(shapeComponent(class RoutesPlayWelcome extends ShapeComponent {
  render() {
    const {t} = useI18n({namespace: "js.routes.play.welcome"})

    return (
      <SurveysLayout className="routes--play--welcome" headerTitle={t(".enter_code_to_begin")} layout="play">
        <SchoolClassCheckInsCodeHandler />
      </SurveysLayout>
    )
  }
}))
