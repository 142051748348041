import {Bar, BarChart, CartesianGrid, Cell, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts"
import {digg, digs} from "diggerize"
import React, {useEffect, useMemo} from "react"
import {SchoolClass, SchoolClassCheckIn, ScoreFactorGroup} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Collection from "@kaspernj/api-maker/build/collection"
import currentDate from "shared/current-date"
import I18n from "shared/i18n"
import {LightenDarkenColor as lightenDarkenColor} from "lighten-darken-color"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {View} from "react-native"

export default memo(shapeComponent(class ComponentsScoreFactorGroupsMoodBarChart extends ShapeComponent {
  static defaultProps = {
    schoolClassCheckInsQuery: null,
    teamSurveysQuery: null,
    weekNumber: undefined
  }

  static propTypes = propTypesExact({
    className: PropTypes.string,
    schoolClass: PropTypes.instanceOf(SchoolClass),
    schoolClassCheckInsQuery: PropTypes.instanceOf(Collection),
    teamSurveysQuery: PropTypes.instanceOf(Collection),
    weekNumber: PropTypes.number
  })

  setup() {
    const {t} = useI18n({namespace: "js.components.score_factor_groups.mood_bar_chart"})
    const {scoreFactorGroup: moodBarChartScoreFactorGroup} = useModel(ScoreFactorGroup, {
      query: ScoreFactorGroup.ransack({identifier_eq: "mood_bar_chart"}),
      select: {
        ScoreFactorGroup: ["id", "name"]
      }
    })

    this.setInstance({
      moodBarChartScoreFactorGroup,
      negativeLabel: t(".negative_label"),
      positiveLabel: t(".positive_label"),
      schoolClassCheckInsQuery: useMemo(
        () => this.newSchoolClassCheckInsQuery(),
        [this.p.schoolClassCheckInsQuery, this.p.teamSurveysQuery, this.p.weekNumber]
      ),
      t
    })
    this.useStates({
      barChartData: undefined
    })

    useEffect(() => {
      if (moodBarChartScoreFactorGroup) {
        this.loadBarChartData()
      }
    }, [moodBarChartScoreFactorGroup?.id(), this.tt.schoolClassCheckInsQuery])
  }

  newSchoolClassCheckInsQuery() {
    if (this.p.schoolClassCheckInsQuery) {
      return this.p.schoolClassCheckInsQuery.clone()
    } else if (this.p.teamSurveysQuery) {
      return null
    }

    const schoolClassCheckInsQuery = SchoolClassCheckIn.ransack({school_class_id_eq: this.p.schoolClass.id(), state_eq: "closed"})

    if (this.p.weekNumber) {
      // Load within week number
      const date = currentDate()
      const weekDayStart = (this.p.weekNumber - 1) * 7 + 3
      const weekStart = new Date(date.getFullYear(), 0, weekDayStart)
      const weekEnd = new Date(date.getFullYear(), 0, weekDayStart + 7)

      schoolClassCheckInsQuery.ransack({
        created_at_gteq: I18n.strftime("%Y-%m-%d", weekStart),
        created_at_lt: I18n.strftime("%Y-%m-%d", weekEnd)
      })
    } else {
      // Load last three
      schoolClassCheckInsQuery.ransack({s: "created_at desc"}).limit(3)
    }

    return schoolClassCheckInsQuery
  }

  async loadBarChartData() {
    if (!this.tt.moodBarChartScoreFactorGroup) throw new Error("moodBarChartScoreFactorGroup hasn't been loaded")

    const result = await this.tt.moodBarChartScoreFactorGroup.barChartData({
      school_class_check_ins_query: this.tt.schoolClassCheckInsQuery,
      team_surveys_query: this.p.teamSurveysQuery
    })
    const {score_factors_data: scoreFactorsData} = digs(result, "score_factors_data")
    const barChartData = []

    for (const scoreFactorData of scoreFactorsData) {
      const {
        name,
        negative_score: negativeScore,
        positive_score: positiveScore,
        score_factor: scoreFactor
      } = digs(
        scoreFactorData,
        "name",
        "negative_score",
        "positive_score",
        "score_factor"
      )

      if (negativeScore === null && positiveScore === null) continue

      const cellData = {
        name,
        fill: scoreFactor.color() || "#676767",
        secondaryColor: scoreFactor.secondaryColor()
      }

      cellData[this.tt.negativeLabel] = negativeScore
      cellData[this.tt.positiveLabel] = positiveScore

      barChartData.push(cellData)
    }

    this.setState({barChartData})
  }

  render() {
    const {negativeLabel, positiveLabel} = this.tt
    const {barChartData} = this.s

    return (
      <View dataSet={{class: this.props.className, component: "score-factor-groups/mood-bar-chart"}}>
        {barChartData &&
          <View dataSet={{class: "mood-bar-chart"}} style={{height: 300}}>
            <ResponsiveContainer height="100%" width="100%">
              <BarChart
                data={barChartData}
                height={300}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5
                }}
                stackOffset="sign"
                width={500}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis tick={false} />
                <Tooltip />
                <ReferenceLine stroke="#000" y={0} />
                <Bar barSize={11} dataKey={positiveLabel} radius={[5, 5, 0, 0]} stackId="A" />
                <Bar barSize={11} dataKey={negativeLabel} radius={[5, 5, 0, 0]} stackId="A">
                  {barChartData.map((datum, _entry, index) => (
                    <Cell fill={this.cellNegativeFillColor(datum)} key={`cell-${index}`} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </View>
        }
      </View>
    )
  }

  cellNegativeFillColor(datum) {
    const color = digg(datum, "fill") || "#676767"
    const colorLight = datum.secondaryColor || lightenDarkenColor(color, 100)

    return colorLight
  }
}))
