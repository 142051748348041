import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Card from "components/card"
import classNames from "classnames"
import currentDate from "shared/current-date"
import ElementInstance from "components/survey-step-elements/element-instance"
import memo from "set-state-compare/src/memo"
import moment from "shared/moment"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import React from "react"
import ScoreFactorsPositiveNegativeAreaChart from "components/score-factors/positive-negative-area-chart"
import {TeamSurvey} from "models"

export default memo(shapeComponent(class ComponentsSurveyStepElementsPartialTypesDevelopmentInScoreFactorChart extends ShapeComponent {
  static propTypes = propTypesExact({
    className: PropTypes.string,
    elementInstance: PropTypes.instanceOf(ElementInstance).isRequired
  })

  setup() {
    this.useStates({
      dateFrom: () => moment().subtract(4, "week").toDate(),
      dateTo: () => currentDate(),
      teamSurveys: () => TeamSurvey.ransack({
        preview_false: true,
        school_class_check_in_school_class_id_eq: this.props.elementInstance.data.schoolClass.id(),
        state_eq: "completed"
      })
    })
  }

  render() {
    const {className, elementInstance} = this.props
    const {element} = elementInstance.data
    const {dateFrom, dateTo, teamSurveys} = this.s
    const scoreFactor = element.scoreFactor()

    return (
      <div
        className={classNames("components--survey-step-elements--partial-types--development-in-score-factor-chart", className)}
        style={{display: "flex", justifyContent: "center"}}
      >
        <Card header={scoreFactor.name()} style={{width: "100%", maxWidth: "1100px", marginTop: "40px"}}>
          <ScoreFactorsPositiveNegativeAreaChart
            dateFrom={dateFrom}
            dateTo={dateTo}
            key={scoreFactor.id()}
            scoreFactor={scoreFactor}
            teamSurveys={teamSurveys}
          />
        </Card>
      </div>
    )
  }
}))
