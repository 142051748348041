import React, {useMemo} from "react"
import RenderHtml, {defaultSystemFonts} from "react-native-render-html"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import memo from "set-state-compare/src/memo"

const systemFonts = [...defaultSystemFonts, "Nunito"]
const tagsStyles = {
  body: {
    fontFamily: "Nunito"
  }
}

export default memo(shapeComponent(class SharedRenderHtml extends ShapeComponent {
  render() {
    const {source, ...restProps} = this.props
    const sourceProps = useMemo(
      () => ({html: source}),
      [source]
    )

    return (
      <RenderHtml source={sourceProps} systemFonts={systemFonts} tagsStyles={tagsStyles} {...restProps} />
    )
  }
}))
