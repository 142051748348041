import {TeamSurvey, TeamSurveyStep} from "models"
import {digg} from "diggerize"
import SurveysSelectedAttributes from "components/surveys/selected-attributes"

const teamSurveyStepPreloads = ["team_survey_step_elements.answers"]
const teamSurveyStepSelects = Object.assign({}, SurveysSelectedAttributes.select)

teamSurveyStepSelects["TeamSurveyStep"] = ["id", "teamSurveyId"]
teamSurveyStepSelects["TeamSurveyStepElement"] = ["id", "parsedContent", "parsedMainContent", "state", "surveyStepElementId"]
teamSurveyStepSelects["TeamSurveyStepElementAnswer"] = ["id", "surveyStepElementOptionId", "text"]

for (const surveyStepPreload of SurveysSelectedAttributes.preload) {
  teamSurveyStepPreloads.push(
    `survey_step.${surveyStepPreload}`
  )
}

const teamSurveyStepQuery = TeamSurveyStep
  .ransack()
  .preload(teamSurveyStepPreloads)
  .select(teamSurveyStepSelects)

const findOrCreateTeamSurveyStep = async ({surveyStepId, teamSurveyId, teamSurveyStepId, withQuery}) => {
  if (!teamSurveyStepId && !surveyStepId) throw new Error("No 'surveyStepId' was given")
  if (!teamSurveyStepId && !teamSurveyId) throw new Error("No 'teamSurveyId' was given")

  let query = teamSurveyStepQuery

  if (withQuery) {
    query = await withQuery({query: query.clone()})
  }

  const findOrCreateTeamSurveyStepResult = await TeamSurvey.findOrCreateTeamSurveyStep({
    query,
    survey_step_id: surveyStepId,
    team_survey_id: teamSurveyId,
    team_survey_step_id: teamSurveyStepId
  })

  const teamSurveyStep = digg(findOrCreateTeamSurveyStepResult, "collection_result", 0)

  if (!teamSurveyStep.isAssociationLoaded("teamSurveyStepElements")) {
    throw new Error("'teamSurveyStepElements' wasn't loaded on 'teamSurveyStep'")
  }

  return teamSurveyStep
}

export default findOrCreateTeamSurveyStep
