import React, {useMemo} from "react"
import {SchoolClassCheckIn, TeamSurvey} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AppHistory from "shared/history"
import Button from "components/inputs/button"
import findOrCreateTeamSurveyStep from "components/survey-steps/find-or-create-team-survey-step"
import memo from "set-state-compare/src/memo"
import RoutesPlay from "shared/routes-play"
import setLayout from "shared/set-layout"
import SurveysLayout from "components/surveys/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"

export default memo(shapeComponent(class RoutesPlaySchoolClassCheckInsUserLobby extends ShapeComponent {
  setup() {
    setLayout("play")

    const {t} = useI18n({namespace: "js.routes.user.school_class_check_ins.user_lobby"})
    const {schoolClassCheckIn, SchoolClassCheckInId, schoolClassCheckInNotFound} = useModel(SchoolClassCheckIn, {
      match: this.p.match,
      preload: ["check_in.survey.steps"],
      select: {
        SchoolClassCheckIn: ["id", "userLobby"],
        Survey: ["id"],
        SurveyStep: ["id", "position"]
      }
    })
    const {teamSurvey, teamSurveyId} = useModel(TeamSurvey, {
      match: this.p.match,
      preload: ["team.school_class", "team_survey_steps.survey_step"],
      select: {
        SchoolClass: ["id", "schoolId"],
        SurveyStep: ["position"],
        Team: ["id"],
        TeamSurvey: ["id"]
      }
    })

    this.setInstance({schoolClassCheckIn, SchoolClassCheckInId, schoolClassCheckInNotFound, teamSurvey, teamSurveyId, t})

    useMemo(() => {
      if (schoolClassCheckIn) {
        this.onSchoolClassCheckInLoad()
      }
    }, [schoolClassCheckIn?.id()])
  }

  async onSchoolClassCheckInLoad() {
    const {schoolClassCheckIn, teamSurvey} = this.tt

    if (!schoolClassCheckIn.userLobby()) {
      const teamSurveyStep = await this.teamSurveyStep()

      AppHistory.replace(RoutesPlay.schoolClassCheckInTeamSurveyTeamSurveyStepPath(schoolClassCheckIn.id(), teamSurvey.id(), teamSurveyStep.id()))
    }
  }

  async teamSurveyStep() {
    const {teamSurvey} = this.tt
    const surveyStep = this.firstStep()

    if (!surveyStep) throw new Error("No 'surveyStep'")
    if (!teamSurvey) throw new Error("No 'teamSurvey'")

    return await findOrCreateTeamSurveyStep({surveyStepId: surveyStep.id(), teamSurveyId: teamSurvey.id()})
  }

  render() {
    const {schoolClassCheckIn, schoolClassCheckInNotFound, t, teamSurvey} = this.tt

    return (
      <SurveysLayout
        dataSet={{route: "user/school-class-check-ins/user-lobby"}}
        layout="play"
        notFound={schoolClassCheckInNotFound}
        schoolClass={teamSurvey?.team()?.schoolClass()}
      >
        {schoolClassCheckIn && teamSurvey && this.firstStep() &&
          <Button
            className="start-button"
            label={t(".start")}
            onClick={this.tt.onStartSchoolClassCheckInClicked}
            primary
            style={{width: "100%"}}
          />
        }
      </SurveysLayout>
    )
  }

  onStartSchoolClassCheckInClicked = async () => {
    const {schoolClassCheckIn, teamSurvey} = this.tt
    const teamSurveyStep = await this.teamSurveyStep()

    AppHistory.push(RoutesPlay.schoolClassCheckInTeamSurveyTeamSurveyStepPath(schoolClassCheckIn.id(), teamSurvey.id(), teamSurveyStep.id()))
  }

  firstStep() {
    const {schoolClassCheckIn} = this.tt

    if (!schoolClassCheckIn) return null

    const steps = schoolClassCheckIn.checkIn().survey().steps().loaded().sort((step1, step2) => step1.position() - step2.position())

    return steps[0]
  }

  firstTeamSurveyStep() {
    const {teamSurvey} = this.tt

    if (!teamSurvey) return null

    const teamSurveySteps = teamSurvey.teamSurveySteps().loaded().sort((teamSurveyStep1, teamSurveyStep2) =>
      teamSurveyStep1.surveyStep()?.position() - teamSurveyStep2.surveyStep()?.position()
    )

    return teamSurveySteps[0]
  }
}))
