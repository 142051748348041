import {Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import {digs} from "diggerize"
import I18n from "shared/i18n"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import {ScoreFactor} from "models"

export default memo(shapeComponent(class ComponentsScoreFactorsPositiveNegativeAreaChart extends ShapeComponent {
  static propTypes = {
    className: PropTypes.string,
    dateFrom: PropTypes.instanceOf(Date).isRequired,
    dateTo: PropTypes.instanceOf(Date).isRequired,
    scoreFactor: PropTypes.instanceOf(ScoreFactor).isRequired,
    teamSurveys: PropTypes.object.isRequired
  }

  setup() {
    this.useStates({
      chartData: undefined
    })

    useMemo(() => {
      this.loadChartData()
    }, [])
  }

  async loadChartData() {
    const {dateFrom, dateTo, scoreFactor, teamSurveys} = this.p
    const result = await scoreFactor.positiveNegativeData({
      date_from: dateFrom,
      date_to: dateTo,
      team_surveys: teamSurveys
    })
    const {dates} = digs(result, "dates")
    const chartData = []

    for (const dateResult of dates) {
      const {date, negative_score: negativeScore, positive_score: positiveScore} = digs(dateResult, "date", "negative_score", "positive_score")

      chartData.push({
        name: I18n.strftime("%Y-%m-%d", date),
        negativeScore,
        positiveScore
      })
    }

    this.setState({chartData})
  }

  render() {
    const {className, dateFrom, dateTo, scoreFactor, teamSurveys, ...restProps} = this.props
    const {chartData} = this.s

    return (
      <div
        className={classNames("components--score-factors--positive-negative-area-chart", className)}
        data-chart-data-loaded={Boolean(chartData)}
        style={{height: "300px"}}
        {...restProps}
      >
        {chartData &&
          <ResponsiveContainer height="100%" width="100%">
            <AreaChart
              data={chartData}
              height={400}
              margin={{
                bottom: 0,
                left: 0,
                right: 30,
                top: 10
              }}
              width={500}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Area connectNulls dataKey="negativeScore" fill="#ffdbdb" stroke="#ff4c4c" strokeWidth={3} type="monotone" />
              <Area connectNulls dataKey="positiveScore" fill="#dbe9ff" stroke="#4c93ff" strokeWidth={3} type="monotone" />
            </AreaChart>
          </ResponsiveContainer>
        }
      </div>
    )
  }
}))
