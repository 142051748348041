import fetchingObject from "fetching-object"
export default class ElementInstance {
  data = fetchingObject(() => this.dataHolder)

  constructor() {
    this.dataHolder = {}
    this.elementIndex = 0
  }

  set(newData) {
    for (const key in newData) {
      this.dataHolder[key] = newData[key]
    }
  }

  setFromSurveyInstance(surveyInstance) {
    this.surveyInstance = surveyInstance

    for (const key in surveyInstance.dataHolder) {
      this.dataHolder[key] = surveyInstance.dataHolder[key]
    }
  }
}
