import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import CkeditorPresentation from "ckeditor/presentation"
import ContentsPresentation from "components/contents/presentation"
import ElementInstance from "components/survey-step-elements/element-instance"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import React from "react"

export default memo(shapeComponent(class ComponentsSurveyStepElementsContent extends ShapeComponent {
  static propTypes = propTypesExact({
    elementInstance: PropTypes.instanceOf(ElementInstance).isRequired
  })

  render() {
    const {elementInstance} = this.p
    const {element, teamSurveyStepElement} = elementInstance.data
    const absoluteContainers = element.contentModel()?.absoluteContainers()?.loaded()

    if (!teamSurveyStepElement) throw new Error("No 'teamSurveyStepElement' was given in props")

    if (element.elementType() == "fixed_content" && (teamSurveyStepElement.hasParsedContent() || absoluteContainers?.length > 0)) {
      return (
        <ContentsPresentation
          content={element.contentModel()}
          elementInstance={elementInstance}
          parsedContent={teamSurveyStepElement.parsedContent() || ""}
          resource={teamSurveyStepElement}
        />
      )
    }

    if (element.elementType() != "fixed_content" && teamSurveyStepElement.hasParsedMainContent()) {
      return <CkeditorPresentation html={teamSurveyStepElement.parsedMainContent() || ""} />
    }

    return null
  }
}))
