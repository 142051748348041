import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import ElementInstance from "components/survey-step-elements/element-instance"
import memo from "set-state-compare/src/memo"
import OptionElement from "./option-element"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import React from "react"
import SurveyStepElementsContent from "components/survey-step-elements/content"

export default memo(shapeComponent(class ComponentsSurveyStepElementsPartialTypesRadioButtons extends ShapeComponent {
  static propTypes = propTypesExact({
    className: PropTypes.string,
    elementInstance: PropTypes.instanceOf(ElementInstance).isRequired
  })

  setup() {
    this.useStates({
      optionsChecked: () => this.defaultOptionsChecked()
    })
  }

  defaultOptionsChecked() {
    const optionsChecked = {}

    for (const option of this.element().options().loaded()) {
      const answer = this.answerForOption(option)

      if (answer) {
        optionsChecked[option.id()] = true
      } else {
        optionsChecked[option.id()] = false
      }
    }

    return optionsChecked
  }

  render() {
    const {className, elementInstance} = this.props
    const {optionsChecked} = this.s

    return (
      <div className={classNames("components--survey-step-elements--partial-types--radio-buttons", className)}>
        <SurveyStepElementsContent elementInstance={elementInstance} />
        {this.optionsInOrder().map((option, optionIndex) =>
          <OptionElement
            answer={this.answerForOption(option)}
            checked={optionsChecked[option.id()]}
            className="mb-1"
            elementInstance={elementInstance}
            key={option.id()}
            onChange={this.tt.onOptionChanged}
            option={option}
            optionIndex={optionIndex}
          />
        )}
      </div>
    )
  }

  answerForOption = (option) => this.teamSurveyStepElement()?.answers()?.loaded()
    ?.find((answer) => answer.surveyStepElementOptionId() == option.id())

  element = () => this.p.elementInstance.data.element
  teamSurveyStepElement = () => this.p.elementInstance.data.teamSurveyStepElement

  onOptionChanged = ({checked, option}) => {
    const maxChoices = this.element().maxChoices()
    const newOptionsChecked = {...this.s.optionsChecked}

    newOptionsChecked[option.id()] = checked

    // Find out how many options are checked
    let choicesChecked = 0

    for (const optionId in newOptionsChecked) {
      const checked = newOptionsChecked[optionId]

      if (checked) choicesChecked++
    }

    // Remove any other left over options more than allowed
    if (maxChoices > 0 && choicesChecked > maxChoices) {
      let removeChoices = choicesChecked - maxChoices

      for (const optionId in newOptionsChecked) {
        const checked = newOptionsChecked[optionId]

        if (checked && removeChoices > 0 && optionId != option.id()) {
          newOptionsChecked[optionId] = false
          removeChoices--
        }
      }
    }

    // And finally set the new state
    this.setState({optionsChecked: newOptionsChecked})
  }

  optionsInOrder = () => this.element().options().loaded().sort((option1, option2) => option1.position() - option2.position())
}))
