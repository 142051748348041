import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import ElementInstance from "components/survey-step-elements/element-instance"
import Input from "components/inputs/input"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import React from "react"
export default memo(shapeComponent(class ComponentsSurveyStepElementsPartialTypesText extends ShapeComponent {
  static propTypes = propTypesExact({
    className: PropTypes.string,
    elementInstance: PropTypes.instanceOf(ElementInstance).isRequired
  })

  render() {
    const {className, elementInstance} = this.props
    const {element, teamSurveyStepElement} = elementInstance.data
    const answers = teamSurveyStepElement?.answers()?.loaded()
    let answer

    if (answers) answer = answers[0]

    return (
      <div className={classNames("components--survey-step-elements--partial-types--text", className)}>
        {answer &&
          <input name={`${this.baseInputName()}[id]`} type="hidden" value={answer.id()} />
        }
        <Input
          defaultValue={answer?.text()}
          id={`team-survey-step-element-input-${element.id()}`}
          label={element.name()}
          name={this.inputName()}
        />
      </div>
    )
  }

  baseInputName = () => `team_survey_step[team_survey_step_elements_attributes][${this.p.elementInstance.data.elementIndex}][answers_attributes][0]`
  inputName = () => `${this.baseInputName()}[text]`
}))
