import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import {digg} from "diggerize"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import {Text} from "shared/base"
import {View} from "react-native"
const headingStyles = {
  heading1: {
    fontSize: "34px"
  },
  heading2: {
    fontSize: "27px"
  },
  heading3: {
    fontSize: "20px"
  }
}

export default memo(shapeComponent(class UtilsHeading extends ShapeComponent {
  static propTypes = {
    description: PropTypes.string,
    headingStyle: PropTypes.object,
    level: PropTypes.number,
    noMargin: PropTypes.bool
  }

  render() {
    const {children, description, headingStyle, level = 1, noMargin, ...restProps} = this.props
    const actualHeadingStyle = Object.assign({...digg(headingStyles, `heading${level}`)}, headingStyle)
    const descriptionStyle = {fontWeight: "bold"}

    if (description && !noMargin) {
      descriptionStyle.marginBottom = ".67em"

      if (level == 1) {
        actualHeadingStyle.marginBottom = "12px"
      }
    } else if (!noMargin) {
      actualHeadingStyle.marginBottom = ".67em"
    }

    return (
      <>
        <View {...restProps}>
          <Text style={actualHeadingStyle}>
            {children}
          </Text>
        </View>
        {description &&
          <View>
            <Text style={descriptionStyle}>
              {description}
            </Text>
          </View>
        }
      </>
    )
  }
}))
