export default class AbsoluteHeightCalculator {
  constructor(rootElement) {
    if (!rootElement) throw new Error("No 'rootElement' was given")

    this.rootElement = rootElement
  }

  calculate() {
    let maxWidth = 0
    let maxHeight = 0

    for (const child of this.rootElement.children) {
      const {width, height} = this.calculateForElement(child, 0, 0)

      if (width > maxWidth) maxWidth = width
      if (height > maxHeight) maxHeight = height
    }

    return {
      width: maxWidth,
      height: maxHeight
    }
  }

  calculateForElement(element, offsetLeft, offsetTop) {
    if (!element) throw new Error("No 'element' was given")

    const elementWidth = element.offsetWidth
    const elementHeight = element.offsetHeight

    const elementLeftRaw = getComputedStyle(element)["left"]
    const elementLeftPixelsMatch = elementLeftRaw.match(/^((|-)[\d.]+)px$/)

    const elementTopRaw = getComputedStyle(element)["top"]
    const elementTopPixelsMatch = elementTopRaw.match(/^((|-)[\d.]+)px$/)

    let elementLeft, elementTop

    if (elementLeftPixelsMatch) {
      elementLeft = Number(elementLeftPixelsMatch[1])
    } else if (elementLeftRaw == "auto") {
      elementLeft = 0
    } else {
      throw new Error(`Couldn't figure out left from ${elementLeftRaw}`)
    }

    if (elementTopPixelsMatch) {
      elementTop = Number(elementTopPixelsMatch[1])
    } else if (elementTopRaw == "auto") {
      elementTop = 0
    } else {
      throw new Error(`Couldn't figure out top from ${elementTopRaw}`)
    }

    let maxWidth = offsetLeft + elementWidth + elementLeft
    let maxHeight = offsetTop + elementHeight + elementTop

    for (const child of element.children) {
      if (!child) throw new Error("Empty 'child' in 'children' array?")

      const {width: descendantsMaximumWidth, height: descendantsMaximumHeight} = this.calculateForElement(
        child,
        offsetLeft + elementLeft,
        offsetTop + elementTop
      )

      if (descendantsMaximumWidth > maxWidth) maxWidth = descendantsMaximumWidth
      if (descendantsMaximumHeight > maxHeight) maxHeight = descendantsMaximumHeight
    }

    return {
      width: maxWidth,
      height: maxHeight
    }
  }
}
