import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import CkeditorPresentation from "ckeditor/presentation"
import classNames from "classnames"
import ElementInstance from "components/survey-step-elements/element-instance"
import Input from "components/inputs/input"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import {SchoolClassContentPiece} from "models"
export default memo(shapeComponent(class ComponentsSurveyStepElementsPartialTypesContentPiece extends ShapeComponent {
  static propTypes = propTypesExact({
    className: PropTypes.string,
    elementInstance: PropTypes.instanceOf(ElementInstance).isRequired
  })

  setup() {
    this.useStates({
      schoolClassContentPiece: undefined,
      schoolClassContentPieceLoaded: false
    })
    useMemo(() => {
      this.loadSchoolClassContentPiece()
    }, [])
  }

  async loadSchoolClassContentPiece() {
    const schoolClassContentPiece = await SchoolClassContentPiece
      .ransack({
        content_piece_type_id_eq: this.element().contentPieceTypeId(),
        latest_for_school_class: true,
        school_class_id_eq: this.p.elementInstance.data.schoolClass.id()
      })
      .select({SchoolClassContentPiece: ["content"]})
      .first()

    this.setState({
      schoolClassContentPiece,
      schoolClassContentPieceLoaded: true
    })
  }

  render() {
    const {className, elementInstance} = this.props
    const {element, teamSurveyStepElement} = elementInstance.data
    const answers = teamSurveyStepElement?.answers()?.loaded()
    let answer

    if (answers) answer = answers[0]

    return (
      <div className={classNames("components--survey-step-elements--partial-types--content-piece", className)}>
        {answer &&
          <input name={`${this.baseInputName()}[id]`} type="hidden" value={answer.id()} />
        }
        {this.s.schoolClassContentPieceLoaded &&
          <div style={{textAlign: "left"}}>
            {element.editable() &&
              <Input
                defaultValue={this.s.schoolClassContentPiece?.content()}
                id={`team-survey-step-element-input-${element.id()}`}
                label={element.name()}
                name={this.inputName()}
                resource={element}
                type="ckeditor"
              />
            }
            {!element.editable() && this.s.schoolClassContentPiece?.hasContent() &&
              <CkeditorPresentation className="content-piece" html={this.s.schoolClassContentPiece?.content()} />
            }
          </div>
        }
      </div>
    )
  }

  baseInputName = () => `team_survey_step[team_survey_step_elements_attributes][${this.elementIndex()}][answers_attributes][0]`
  element = () => this.p.elementInstance.data.element
  elementIndex = () => this.p.elementInstance.data.elementIndex
  inputName = () => `${this.baseInputName()}[text]`
}))
