import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import LayoutBase from "components/layout/base"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import {Text} from "shared/base"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import {View} from "react-native"

export default memo(shapeComponent(class ComponentsSurveysLayout extends ShapeComponent {
  static propTypes = {
    className: PropTypes.string,
    notFound: PropTypes.bool
  }

  setup() {
    const {t} = useI18n({namespace: "js.components.surveys.layout"})

    this.setInstance({t})
  }

  render() {
    const {t} = this.tt
    const {children, className, headerTitle, notFound, ...restProps} = this.props

    return (
      <LayoutBase
        dataSet={{class: className, component: "surveys/layout"}}
        headerTitle={headerTitle}
        layout="survey"
        style={{
          paddingTop: 10,
          paddingBottom: 10
        }}
        {...restProps}
      >
        <View
          dataSet={{class: "survey-content-container"}}
          style={{
            flex: 1,
            width: "100%",
            maxWidth: 355,
            padding: 24,
            borderRadius: 10,
            marginRight: "auto",
            marginLeft: "auto",
            backgroundColor: "#f6f4f4"
          }}
        >
          {notFound &&
            <Text>
              {t(".not_found_or_no_access")}
            </Text>
          }
          {!notFound && headerTitle &&
            <h1>{headerTitle}</h1>
          }
          {children}
        </View>
      </LayoutBase>
    )
  }
}))
