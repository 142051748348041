import {shapeComponent, ShapeComponent} from "set-state-compare/src/shape-component"
import {SurveyStepElementOption, TeamSurveyStepElementAnswer} from "models"
import ElementInstance from "components/survey-step-elements/element-instance"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import React from "react"

export default memo(shapeComponent(class ComponentsSurveyStepElementsPartialTypesImageOptionsOptionElement extends ShapeComponent {
  static propTypes = propTypesExact({
    answer: PropTypes.instanceOf(TeamSurveyStepElementAnswer),
    elementInstance: PropTypes.instanceOf(ElementInstance).isRequired,
    option: PropTypes.instanceOf(SurveyStepElementOption).isRequired,
    optionIndex: PropTypes.number.isRequired
  })

  setup() {
    this.useStates({
      checked: Boolean(this.props.answer)
    })
  }

  render() {
    const {onOptionChanged} = this.tt
    const {answer, option} = this.p
    const {checked} = this.s

    return (
      <div
        className="components--survey-step-elements--partial-types--image-options--option-element"
        data-checked={checked}
        data-has-background-image={option.hasImageUrl()}
        data-has-font-awesome-icon={option.hasFontAwesomeIcon()}
        data-option-id={option.id()}
        onClick={onOptionChanged}
      >
        {answer &&
          <input name={`${this.baseInputName()}[id]`} type="hidden" value={answer.id()} />
        }
        {answer && !checked &&
          <input name={`${this.baseInputName()}[_destroy]`} type="hidden" value="1" />
        }
        <input name={this.inputName()} type="hidden" value={option.id()} />
        {option.hasImageUrl() &&
          <div className="option-background-image" style={{backgroundImage: `url('${option.imageUrl()}')`}} />
        }
        {option.hasFontAwesomeIcon() &&
          <div className="option-icon-container">
            <i className={`fa fa-${option.fontAwesomeIcon()}`} />
          </div>
        }
        {!option.hideLabel() &&
          <div className="option-label" style={option.textStyle()}>
            {option.name()}
          </div>
        }
      </div>
    )
  }

  baseInputName() {
    const {elementInstance, optionIndex} = this.p

    return `team_survey_step[team_survey_step_elements_attributes][${elementInstance.data.elementIndex}][answers_attributes][${optionIndex}]`
  }

  inputName() {
    const {checked} = this.s

    if (checked) {
      return `${this.baseInputName()}[survey_step_element_option_id]`
    }
  }

  onOptionChanged = () =>
    this.setState({
      checked: !this.state.checked
    })
}))
