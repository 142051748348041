import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import ElementInstance from "components/survey-step-elements/element-instance"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import React from "react"
import SurveyStepElementsContent from "components/survey-step-elements/content"

export default memo(shapeComponent(class ComponentsSurveyStepElementsPartialTypesFixedContent extends ShapeComponent {
  static propTypes = propTypesExact({
    className: PropTypes.string,
    elementInstance: PropTypes.instanceOf(ElementInstance).isRequired
  })

  render() {
    const {className, elementInstance} = this.props

    return (
      <div className={classNames("components--survey-step-elements--partial-types--fixed-content", className)}>
        <SurveyStepElementsContent elementInstance={elementInstance} />
      </div>
    )
  }
}))
