import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import React from "react"
import SurveyInstance from "components/surveys/survey-instance"
import SurveyStepElementsPartial from "components/survey-step-elements/partial"

export default memo(shapeComponent(class ComponentsSurveyStepsElements extends ShapeComponent {
  static propTypes = propTypesExact({
    className: PropTypes.string,
    surveyInstance: PropTypes.instanceOf(SurveyInstance).isRequired
  })

  render() {
    const {className, surveyInstance} = this.props

    return (
      <div className={classNames("components--survey-steps--elements", className)}>
        {this.elementsInOrder().map((element) =>
          <SurveyStepElementsPartial
            className="mb-4"
            element={element}
            key={element.id()}
            surveyInstance={surveyInstance}
          />
        )}
      </div>
    )
  }

  elementsInOrder = () => this.p.surveyInstance.data.surveyStep.elements().loaded()
    .filter((element) => !element.contentAbsoluteContainer() && element.state() == "done")
    .sort((element1, element2) => element1.position() - element2.position())
}))
