const preload = ["elements.content_absolute_container", "elements.content_model.absolute_containers", "elements.options", "elements.score_factor"]

const select = {
  Content: ["id", "parsedContent"],
  ContentAbsoluteContainer: ["id", "liquidConditionsResult", "minimumHeight", "minimumWidth"],
  ScoreFactor: ["id", "name"],
  SurveyStep: ["id", "name", "position", "surveyId"],
  SurveyStepElement: ["contentPieceTypeId", "editable", "elementType", "id", "mainContent", "maxChoices", "name", "parsedContent", "position", "state"],
  SurveyStepElementOption: ["fontAwesomeIcon", "hideLabel", "id", "imageUrl", "name", "position", "textStyle"]
}

export default {preload, select}
