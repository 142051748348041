import React, {createContext} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import {digg} from "diggerize"
import InvalidFeedback from "@kaspernj/api-maker/build/bootstrap/invalid-feedback"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import useInput from "@kaspernj/api-maker/build/use-input"

const InputContainerContext = createContext(null)

const ComponentsInputsInputContainer = memo(shapeComponent(class ComponentsInputsInputContainer extends ShapeComponent {
  static propTypes = {
    hint: PropTypes.node,
    inputProps: PropTypes.object.isRequired
  }

  render() {
    const {children, hint, icon, inputContainerProps, inputProps, labelStyle, wrapperOpts} = this.props

    if (!inputProps) throw new Error("No 'inputProps' was given")

    return (
      <InputContainerContext.Provider value="withinInputContainer">
        <div className="components--inputs--input-container" data-input-id={inputProps.id} data-type={inputProps.type} {...inputContainerProps}>
          {inputProps.type == "checkbox" &&
            <>
              <div className="input-checkbox">
                {children}
                <label className="checkbox-label" htmlFor={inputProps.id} style={labelStyle}>
                  {digg(wrapperOpts, "label")}
                </label>
              </div>
            </>
          }
          {inputProps.type != "checkbox" &&
            <div className="input-container-legend" data-has-icon={Boolean(icon)}>
              <div className="label-container" style={labelStyle}>
                {wrapperOpts.label}
              </div>
              {icon &&
                <i className={`input-icon fa fa-fw fa-${icon}`} />
              }
              {children}
            </div>
          }
          {hint &&
            <div className="input-container-hint">
              {hint}
            </div>
          }
          {wrapperOpts.errors?.length > 0 &&
            <InvalidFeedback errors={wrapperOpts.errors} />
          }
        </div>
      </InputContainerContext.Provider>
    )
  }
}))

const withInputContainer = (WrappedComponent, wrapperOptions) => memo((props) => {
  const {inputProps, wrapperOpts} = useInput({props, wrapperOptions})

  return (
    <ComponentsInputsInputContainer inputProps={inputProps} wrapperOpts={wrapperOpts}>
      <WrappedComponent {...props} />
    </ComponentsInputsInputContainer>
  )
})

export {InputContainerContext, withInputContainer}
export default ComponentsInputsInputContainer
