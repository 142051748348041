import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AppHistory from "shared/history"
import Button from "components/inputs/button"
import classNames from "classnames"
import {digg} from "diggerize"
import FlashMessage from "shared/flash-message"
import Input from "components/inputs/input"
import memo from "set-state-compare/src/memo"
import React from "react"
import RoutesPlay from "shared/routes-play"
import {SchoolClassCheckIn} from "models"
import usersUpdateSession from "components/users/update-session"

export default memo(shapeComponent(class ComponentsSchoolClassCheckInsCodeHandler extends ShapeComponent {
  render() {
    const {className, ...restProps} = this.props

    return (
      <div className={classNames("components--school-class-check-ins--code-handler", className)} {...restProps}>
        <form autoComplete="off" onSubmit={this.tt.onSubmit}>
          <Input autoComplete="false" className="code-input" label={SchoolClassCheckIn.humanAttributeName("code")} name="code" />
          <Button primary style={{width: "100%"}}>
            <i className="fa fa-chevron-right" />
          </Button>
        </form>
      </div>
    )
  }

  onSubmit = async (e) => {
    e.preventDefault()

    const form = digg(e, "target")
    const formData = new FormData(form)
    const code = formData.get("code")

    try {
      const result = await SchoolClassCheckIn.codeLookup({code})
      const schoolClassCheckIn = digg(result, "school_class_check_in")
      const firstTeamSurveyStep = digg(result, "first_team_survey_step")
      const teamSurvey = digg(result, "team_survey")
      const user = digg(result, "user")

      if (!user) throw new Error("No user given from code lookup?")

      await usersUpdateSession(user.id())
      AppHistory.push(RoutesPlay.schoolClassCheckInTeamSurveyTeamSurveyStepPath(schoolClassCheckIn.id(), teamSurvey.id(), firstTeamSurveyStep.id()))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
