import ApiMakerConfig from "@kaspernj/api-maker/build/config"

class FakeHistory {
  push = (...args) => ApiMakerConfig.getHistory().push(...args)
  replace = (...args) => ApiMakerConfig.getHistory().replace(...args)
}

const fakeHistory = new FakeHistory()

export default fakeHistory
