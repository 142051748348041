import {shapeComponent, ShapeComponent} from "set-state-compare/src/shape-component"
import {SurveyStepElementOption, TeamSurveyStepElementAnswer} from "models"
import Checkbox from "components/inputs/checkbox"
import classNames from "classnames"
import {digg} from "diggerize"
import ElementInstance from "components/survey-step-elements/element-instance"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import React from "react"
export default memo(shapeComponent(class ComponentsSurveyStepElementsPartialTypesRadioButtonsOptionElement extends ShapeComponent {
  static propTypes = propTypesExact({
    answer: PropTypes.instanceOf(TeamSurveyStepElementAnswer),
    checked: PropTypes.bool.isRequired,
    className: PropTypes.string,
    elementInstance: PropTypes.instanceOf(ElementInstance),
    onChange: PropTypes.func.isRequired,
    option: PropTypes.instanceOf(SurveyStepElementOption).isRequired,
    optionIndex: PropTypes.number.isRequired
  })

  render() {
    const {answer, checked, className, option} = this.props

    return (
      <div className={classNames("components--survey-step-elements--partial-types--radio-buttons--option-element", className)}>
        {answer?.isPersisted() &&
          <input name={`${this.baseInputName()}[id]`} type="hidden" value={answer.id()} />
        }
        {answer?.isPersisted() && !checked &&
          <input name={`${this.baseInputName()}[_destroy]`} type="hidden" value="1" />
        }
        <Checkbox
          checked={checked}
          className="components--survey-step-elements--partial-types--radio-buttons--option-element--checkbox"
          defaultValue={option.id()}
          id={`option-${option.id()}`}
          label={option.hideLabel() ? null : option.name()}
          labelStyle={option.textStyle()}
          name={this.inputName()}
          onChange={this.tt.onCheckBoxChanged}
        />
      </div>
    )
  }

  baseInputName = () => `team_survey_step[team_survey_step_elements_attributes][${this.elementIndex()}][answers_attributes][${this.p.optionIndex}]`
  elementIndex = () => this.p.elementInstance.data.elementIndex

  inputName = () => {
    if (this.p.checked) {
      return `${this.baseInputName()}[survey_step_element_option_id]`
    }
  }

  onCheckBoxChanged = (e) => this.p.onChange({checked: digg(e, "target", "checked"), option: this.p.option})
}))
