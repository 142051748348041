import fetchingObject from "fetching-object"
export default class SurveyInstance {
  data = fetchingObject(() => this.dataHolder)

  constructor() {
    this.dataHolder = {}
    this.elementIndex = 0
  }

  getElementIndex() {
    const elementIndex = this.elementIndex

    this.elementIndex += 1

    return elementIndex
  }

  set(newData) {
    for (const key in newData) {
      this.dataHolder[key] = newData[key]
    }
  }
}
