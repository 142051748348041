import {digg, digs} from "diggerize"
import React, {useMemo, useRef} from "react"
import {SchoolClass, SurveyStep, TeamSurvey, TeamSurveyStep} from "models"
import {shapeComponent, ShapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/inputs/button"
import EventEmitter from "events"
import FlashMessage from "shared/flash-message"
import {loading} from "components/layout/loading-blocker"
import memo from "set-state-compare/src/memo"
import ModelPropType from "@kaspernj/api-maker/build/model-prop-type"
import Params from "@kaspernj/api-maker/build/params"
import PropTypes from "prop-types"
import SurveyInstance from "components/surveys/survey-instance"
import SurveyStepsElements from "components/survey-steps/elements"
import useEventEmitter from "@kaspernj/api-maker/build/use-event-emitter"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"

export default memo(shapeComponent(class ComponentsSurveyStepsPresentation extends ShapeComponent {
  static defaultProps = {
    events: undefined,
    submitButton: true
  }

  static propTypes = {
    events: PropTypes.instanceOf(EventEmitter),
    onAnswersSubmitted: PropTypes.func.isRequired,
    schoolClass: PropTypes.instanceOf(SchoolClass).isRequired,
    submitButton: PropTypes.bool.isRequired,
    surveyStep: PropTypes.instanceOf(SurveyStep),
    surveyStepNotFound: PropTypes.bool,
    teamSurvey: PropTypes.instanceOf(TeamSurvey),
    teamSurveyStep: ModelPropType.ofModel(TeamSurveyStep)
      .withLoadedAssociation("teamSurveyStepElements")
        .withLoadedAssociation("answers") // eslint-disable-line indent
          .withLoadedAttributes(["surveyStepElementOptionId"]) // eslint-disable-line indent
          .previous() // eslint-disable-line indent
        .previous() // eslint-disable-line indent
      .isRequired
  }

  setup() {
    const {t} = useI18n({namespace: "js.components.survey_steps.presentation"})

    this.formRef = useRef()
    this.surveyInstance = useMemo(() => new SurveyInstance())
    this.t = t

    useEventEmitter(this.p.events, "submit", this.tt.onEventsSubmit)
  }

  render() {
    const {formRef, onSubmit} = this.tt
    const {schoolClass, submitButton, surveyStep, teamSurvey, teamSurveyStep} = this.p

    this.surveyInstance.set({schoolClass, surveyStep, teamSurvey, teamSurveyStep})

    if (teamSurvey.id() != teamSurveyStep.teamSurveyId()) {
      throw new Error(`The given team survey step ${teamSurveyStep.id()} didn't belong to the given team survey ${teamSurvey.id()}`)
    }

    return (
      <div
        className="components--survey-steps--presentation"
        data-survey-step-id={surveyStep?.id()}
        data-team-survey-step-id={teamSurveyStep?.id()}
      >
        <form onSubmit={onSubmit} ref={formRef}>
          {surveyStep &&
            <input
              name="team_survey_step[survey_step_id]"
              type="hidden"
              value={surveyStep.id()}
            />
          }
          {teamSurveyStep?.isPersisted() &&
            <input
              name="team_survey_step_id"
              type="hidden"
              value={teamSurveyStep.id()}
            />
          }
          {schoolClass && surveyStep && teamSurveyStep &&
            <SurveyStepsElements surveyInstance={this.surveyInstance} />
          }
          {submitButton && surveyStep && teamSurvey && teamSurveyStep &&
            <Button className="submit-survey-step-button" primary style={{width: "100%"}}>
              <i className="fa fa-chevron-right" />
            </Button>
          }
        </form>
      </div>
    )
  }

  onEventsSubmit = (args) => {
    this.submit(args || {})
  }

  onSubmit = async (e) => {
    e.preventDefault()
    await this.submit({})
  }

  async submit({after}) {
    const {onAnswersSubmitted} = this.props
    const {formRef} = this.tt
    const form = digg(formRef, "current")
    const formData = new FormData(form)
    const {teamSurvey} = this.p
    const loadingText = this.t("js.shared.loading")

    try {
      await loading(loadingText, async () => {
        const response = await teamSurvey.submitAnswers(formData)
        const {next_step: nextStep, next_team_survey_step: nextTeamSurveyStep} = digs(response, "next_step", "next_team_survey_step")

        if (nextTeamSurveyStep && !nextTeamSurveyStep.id()) throw new Error("No ID on 'nextTeamSurveyStep'")

        if (onAnswersSubmitted) {
          await onAnswersSubmitted({after, nextStep, nextTeamSurveyStep})
        }
      })
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }

  async redirectToPreviousStep() {
    const {teamSurvey, teamSurveyStep} = this.s
    const previousTeamSurveyStep = await TeamSurveyStep
      .ransack({
        s: "survey_step_position desc",
        survey_step_position_lt: teamSurveyStep.surveyStep().position(),
        survey_step_survey_id_eq: teamSurvey.surveyId(),
        team_survey_id_eq: teamSurvey.id()
      })
      .first()

    Params.changeParams({survey_step_id: previousTeamSurveyStep.surveyStepId()})
  }
}))
