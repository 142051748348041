import {Content, ContentAbsoluteContainer} from "models"
import React, {useEffect, useMemo, useRef} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AbsoluteContainer from "./absolute-container"
import AbsoluteHeightCalculator from "shared/absolute-height-calculator"
import CkeditorPresentation from "ckeditor/presentation"
import {digg} from "diggerize"
import ElementInstance from "components/survey-step-elements/element-instance"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import RenderHtml from "shared/render-html"
import {Text} from "shared/base"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import {View} from "react-native"

export default memo(shapeComponent(class ComponentsContentsPresentation extends ShapeComponent {
  static defaultProps = {
    debug: false,
    editSurveyStepElements: false,
    reactNative: false
  }

  static propTypes = propTypesExact({
    content: PropTypes.instanceOf(Content).isRequired,
    debug: PropTypes.bool.isRequired,
    elementInstance: PropTypes.instanceOf(ElementInstance),
    editSurveyStepElements: PropTypes.bool.isRequired,
    liquidVariables: PropTypes.object,
    parsedContent: PropTypes.string,
    reactNative: PropTypes.bool.isRequired,
    resource: PropTypes.object
  })

  setup() {
    const {t} = useI18n({namespace: "js.components.contents.presentation.index"})

    this.t = t
    this.useStates({
      content: undefined,
      errors: undefined,
      nestedAbsoluteContainers: undefined,
      parsedBody: undefined,
      relativeElementHeight: undefined
    })
    this.relativeElementRef = useRef()
    useMemo(() => {
      this.loadAbsoluteContainersNestedSet()
    }, [this.p.content.cacheKey()])
    useEffect(() => {
      this.tt.setRelativeElementHeight()
    })
  }

  loadAbsoluteContainersNestedSet = async () => {
    const query = ContentAbsoluteContainer
      .ransack()
      .preload(["styling", "text_keys", "survey_step_element"])
      .select({
        ContentAbsoluteContainer: [
          "backgroundImageUrl",
          "heightPixels",
          "horizontalAlign",
          "id",
          "leftPercent",
          "liquidConditionsResult",
          "minimumHeight",
          "minimumWidth",
          "parentId",
          "responsive",
          "stylingId",
          "topPercent",
          "verticalAlign",
          "widthPercent",
          "widthPixels"
        ],
        Styling: ["styling"],
        SurveyStepElement: ["elementType", "id", "name", "translatedElementType"],
        TextKey: ["id"]
      })

    const loadResult = await this.p.content.load({
      include_parsed_body: true,
      liquid_variables: this.props.liquidVariables,
      raise_errors: this.p.debug,
      resource: this.props.resource,
      query
    })

    const content = digg(loadResult, "contents", 0)
    const errors = digg(loadResult, "errors")
    const parsedBody = digg(loadResult, "parsed_body")
    const nestedAbsoluteContainersList = digg(loadResult, "collection")
    const nestedAbsoluteContainers = {}

    for (const nestedAbsoluteContainer of nestedAbsoluteContainersList) {
      const parentId = nestedAbsoluteContainer.parentId()

      if (!(parentId in nestedAbsoluteContainers)) nestedAbsoluteContainers[parentId] = []

      nestedAbsoluteContainers[parentId].push(nestedAbsoluteContainer)
    }

    this.setState({content, errors, nestedAbsoluteContainers, parsedBody})
  }

  render() {
    const relativeElementStyle = {position: "relative"}
    const {t} = this.tt
    const {reactNative} = this.p
    const {content, errors, nestedAbsoluteContainers, parsedBody, relativeElementHeight} = this.s
    const html = this.props.parsedContent || content?.parsedContent()

    if (relativeElementHeight) relativeElementStyle.height = `${relativeElementHeight}px`

    if (!this.p.editSurveyStepElements && !this.props.step && this.props.teamSurveyStepElement) {
      throw new Error("Step was required for ContentsPresentation but wasn't given")
    }

    return (
      <>
        {errors?.length > 0 &&
          <View dataSet={{class: "liquid-errors"}} style={{marginTop: 40, marginBottom: 40}}>
            <Text style={{color: "red", fontSize: 24, fontWeight: "bold"}}>
              {t(".error_occurred_while_generating_the_preview")}
            </Text>
            {errors.map((error, errorIndex) =>
              <Text key={`${errorIndex}-${error.message}`} style={{color: "red"}}>
                {error.message}
              </Text>
            )}
          </View>
        }
        <div className="components--contents--presentation" ref={this.tt.relativeElementRef} style={relativeElementStyle}>
          {parsedBody !== undefined && this.shownAbsoluteContainers()?.map((absoluteContainer) =>
            <AbsoluteContainer
              absoluteContainer={absoluteContainer}
              editSurveyStepElements={this.p.editSurveyStepElements}
              elementInstance={this.props.elementInstance}
              key={absoluteContainer.id()}
              nestedAbsoluteContainers={nestedAbsoluteContainers}
              parsedBody={parsedBody}
              reactNative={reactNative}
            />
          )}
          {reactNative && content?.hasParsedContent() &&
            <RenderHtml source={html} />
          }
          {!reactNative && content?.hasParsedContent() &&
            <CkeditorPresentation className="preview-content" html={html} />
          }
        </div>
      </>
    )
  }

  setRelativeElementHeight = () => {
    if (!this.tt.relativeElementRef.current) {
      // Maybe element was unmounted while waiting to execute? (maybe the user navigated to something else during timeout?)
      return
    }

    const absoluteHeightCalculator = new AbsoluteHeightCalculator(this.tt.relativeElementRef.current)
    const {height} = absoluteHeightCalculator.calculate()

    this.setState({relativeElementHeight: height})
  }

  shownAbsoluteContainers = () => this.s.nestedAbsoluteContainers &&
    this.s.nestedAbsoluteContainers[null]?.filter((absoluteContainer) => absoluteContainer.liquidConditionsResult())
}))
