import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import Heading from "components/utils/heading"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import unsupportedProps from "components/utils/unsupported-props"
import {View} from "react-native"
export default memo(shapeComponent(class UtilsCard extends ShapeComponent {
  static propTypes = {
    children: PropTypes.node,
    dataSet: PropTypes.object,
    header: PropTypes.string,
    style: PropTypes.object
  }

  render() {
    const {children, dataSet, header, style, ...restProps} = this.props
    const {component, ...restDataSet} = dataSet || {}
    const actualDataSet = Object.assign(
      {component: classNames("utils/card", component)},
      restDataSet
    )

    unsupportedProps(restProps)

    const actualStyle = Object.assign(
      {
        backgroundColor: "#fff",
        borderRadius: 15,
        padding: 50
      },
      style
    )

    return (
      <View dataSet={actualDataSet} style={actualStyle}>
        {header &&
          <Heading level={2}>{header}</Heading>
        }
        {children}
      </View>
    )
  }
}))
