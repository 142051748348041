import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Ckeditor from "ckeditor/editor"
import classNames from "classnames"
import {Input} from "@kaspernj/api-maker/build/inputs/input"
import InputContainer from "components/inputs/input-container"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import {useForm} from "@kaspernj/api-maker/build/form"
import useInput from "@kaspernj/api-maker/build/use-input"

const ComponentsInputsInput = memo(shapeComponent(class ComponentsInputsInput extends ShapeComponent {
  static defaultProps = {
    resize: "vertical"
  }

  static propTypes = {
    resize: PropTypes.string.isRequired
  }

  setup() {
    const {inputProps} = useInput({props: this.props})
    const {defaultValue, name} = inputProps

    this.form = useForm()
    this.setInstance({inputProps})
    this.useStates({value: defaultValue || ""})

    // Set initial value in form
    useMemo(() => {
      if (this.form && name && this.s.value) {
        this.form.setValue(name, this.s.value)
      }
    }, [])
  }

  render() {
    const {className, hint, resize, ...restProps} = this.props
    const {inputProps, wrapperOpts} = useInput({props: this.props})

    if (inputProps.type == "ckeditor") {
      const {ref, type, ...restCkeditorProps} = inputProps

      return (
        <Ckeditor
          inputRef={ref}
          onChange={this.tt.onChange}
          resource={this.props.resource || this.props.model}
          resourceId={restProps.resourceId}
          resourceType={restProps.resourceType}
          {...restCkeditorProps}
        />
      )
    }

    return (
      <Input
        className={classNames("components--inputs--input", className)}
        data-invalid={wrapperOpts.errors?.length > 0}
        data-resize={restProps.type == "textarea" && resize}
        inputProps={inputProps}
        placeholder={wrapperOpts.placeholder}
        wrapperOpts={wrapperOpts}
        {...restProps}
      />
    )
  }

  onChange = ({value}) => {
    const {inputProps, form} = this.tt
    const {name} = inputProps

    if (form && name) {
      form.setValue(name, value)
    }

    if (this.props.onChange) {
      this.props.onChange({value})
    }
  }
}))

const ComponentsInputsInputWithContainer = memo((props) => {
  const {inputProps, restProps, wrapperOpts} = useInput({props, wrapperOptions: {type: "input"}})

  return (
    <InputContainer hint={restProps.hint} inputProps={inputProps} wrapperOpts={wrapperOpts}>
      <ComponentsInputsInput {...props} />
    </InputContainer>
  )
})

export default ComponentsInputsInputWithContainer
