import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import Link from "@kaspernj/api-maker/build/link"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"

export default memo(shapeComponent(class ComponentsInputsButton extends ShapeComponent {
  static defaultProps = {
    darkPrimary: false,
    disabled: false,
    primary: false,
    save: false
  }

  static propTypes = {
    className: PropTypes.string,
    darkPrimary: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    icon: PropTypes.string,
    primary: PropTypes.bool.isRequired,
    save: PropTypes.bool.isRequired,
    small: PropTypes.bool,
    to: PropTypes.string,
    white: PropTypes.bool
  }

  disabled = 0

  setup() {
    const {t} = useI18n({namespace: "js.components.inputs.button"})

    this.t = t
    this.useStates({
      buttonDisabled: false,
      disabled: 0
    })
  }

  render() {
    const {className, darkPrimary, disabled, icon, label, primary, save, small, onClick, to, white, ...restProps} = this.props
    const buttonProps = {
      "className": classNames("components--inputs--button", "action-button", className),
      "data-dark-primary": primary,
      "data-disabled": disabled,
      "data-primary": primary,
      "data-save": save,
      "data-small": small,
      "data-white": white,
      "disabled": this.s.buttonDisabled,
      "onClick": this.tt.onClick
    }

    if (to) {
      return (
        <Link to={disabled ? "#" : to} {...buttonProps} {...restProps}>
          {this.labelContent()}
        </Link>
      )
    }

    return (
      <button {...buttonProps} {...restProps}>
        {this.labelContent()}
      </button>
    )
  }

  isDisabled = () => this.tt.disabled > 0 || this.p.disabled

  label() {
    if ("label" in this.props) {
      return this.props.label
    } else if (this.props.children) {
      return this.props.children
    } else if (this.props.save) {
      return this.t("js.shared.save")
    }

    throw new Error("Couldn't figure out label")
  }

  labelContent() {
    const {icon} = this.props

    return (
      <>
        {icon &&
          <i className={`fa fa-fw fa-${icon} mr-1`} />
        }
        {this.label()}
      </>
    )
  }

  onClick = (e, ...args) => {
    if (this.isDisabled()) {
      e.preventDefault()
      e.stopPropagation()

      return
    }

    this.withDisabled(async () => {
      if (this.props.onClick) {
        await this.p.onClick(e, ...args)
      }
    })
  }

  async withDisabled(callback) {
    this.disableButton()

    try {
      return await callback()
    } finally {
      setTimeout(this.tt.enableButton, 1000)
    }
  }

  disableButton = () => {
    this.disabled += 1
    this.setState({disabled: this.tt.disabled})

    // Wait with setting disabled on button because otherwise form will abot submit
    setTimeout(() => {
      if (this.disabled >= 1) {
        this.setState({buttonDisabled: true})
      }
    }, 100)
  }

  enableButton = () => {
    this.disabled -= 1
    this.setState({
      buttonDisabled: this.tt.disabled >= 1,
      disabled: this.tt.disabled
    })
  }
}))
