import "../build/ckeditor" // Needed for CKEditor styling
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import SanitizedHtml from "shared/sanitized-html"

export default memo(shapeComponent(class CkeditorPresentation extends ShapeComponent {
  static propTypes = {
    className: PropTypes.string,
    html: PropTypes.string.isRequired
  }

  render() {
    const {children, className, html, ...restProps} = this.props

    if (children && children.length > 0) {
      throw new Error("Children are not supported")
    }

    return (
      <SanitizedHtml
        className={classNames("components--ckeditor--presentation", className)}
        html={this.actualHtml()}
        {...restProps}
      />
    )
  }

  actualHtml() {
    const {html} = this.p

    if (html) {
      return `<div class='ck-content'>${html}</div>`
    } else {
      return ""
    }
  }
}))
