import {View, LabeledFieldView, createLabeledInputText, ButtonView, submitHandler} from "@ckeditor/ckeditor5-ui"
import {icons} from "@ckeditor/ckeditor5-core"
import fontAwesomeIcons from "./icons.yml"

export default class FormView extends View {
  constructor(locale) {
    super(locale)

    const I18n = globalThis.i18nOnSteroids?.current

    if (!I18n) throw new Error("I18nOnSteroids current hasn't been set.")

    this.styleInputView = this._createInput(I18n.t("js.shared.style"))
    this.styleInputContainerView = this._createView({
      tag: "div",
      attributes: {class: "style-input"},
      children: this.createCollection([this.styleInputView])
    })

    this.iconInputView = this._createInput(I18n.t("js.shared.icon"), "icon-input")
    this.iconInputContainerView = this._createView({
      tag: "div",
      attributes: {class: "icon-input"},
      children: this.createCollection([this.iconInputView])
    })

    this.saveButtonView = this._createButton(I18n.t("js.shared.save"), icons.check, "ck-button-save")
    this.saveButtonView.type = "submit"

    this.cancelButtonView = this._createButton(I18n.t("js.shared.cancel"), icons.cancel, "ck-button-cancel")
    this.cancelButtonView.delegate("execute").to(this, "cancel")

    const iconsChildren = []

    for (const icon in fontAwesomeIcons) {
      const iconData = fontAwesomeIcons[icon]

      for (const style of iconData.styles) {
        const iconView = this._createView({
          tag: "i",
          attributes: {
            class: [`fa-${style}`, `fa-${icon}`],
            style: "margin: 5px;"
          }
        })

        iconView.render()
        iconView.element.addEventListener("click", this.onIconClicked)
        iconsChildren.push(iconView)
      }
    }

    const iconsChildrenCollection = this.createCollection(iconsChildren)
    const iconsView = this._createView({
      tag: "div",
      attributes: {
        class: ["ck-reset_all-excluded"],
        style: "display: flex; flex-wrap: wrap; max-width: 400px; max-height: 200px; overflow-y: auto;"
      },
      children: iconsChildrenCollection
    })

    this.childViews = this.createCollection([
      this.styleInputContainerView,
      this.iconInputContainerView,
      iconsView,
      this.saveButtonView,
      this.cancelButtonView
    ])

    this.setTemplate( {
      tag: "form",
      attributes: {
        class: ["ck"],
        tabindex: "-1"
      },
      children: this.childViews
    })
  }

  render() {
    super.render()

    // Submit the form when the user clicked the save button or pressed enter in the input.
    submitHandler({view: this})
  }

  focus() {
    this.styleInputView.focus()
  }

  _createInput(label, className) {
    const labeledInput = new LabeledFieldView(this.locale, createLabeledInputText)

    labeledInput.label = label
    labeledInput.render()
    labeledInput.fieldView.element.classList.add(className)

    return labeledInput
  }

  _createButton(label, icon, className) {
    const button = new ButtonView()

    button.set({
      label,
      icon,
      tooltip: true,
      class: className
    })

    return button
  }

  _createView(args) {
    const view = new View()

    view.setTemplate(args)

    return view
  }

  onIconClicked = (e) => {
    e.preventDefault()

    const detectedFontAwesomeClasses = []

    for (const className of e.target.classList) {
      const match = className.match(/^fa-(.+)$/)

      if (match) {
        detectedFontAwesomeClasses.push(match[1])
      }
    }

    const fieldView = this.iconInputView.fieldView
    const styleView = this.styleInputView.fieldView

    fieldView.element.value = detectedFontAwesomeClasses[1]
    fieldView.set({isEmpty: false})

    styleView.element.value = detectedFontAwesomeClasses[0]
    styleView.set({isEmpty: false})
  }
}
