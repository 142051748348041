import I18n from "shared/i18n"
import moment from "moment"

const woofMoment = (...args) => {
  let locale = I18n.locale

  if (locale == "se") locale = "sv"

  moment.locale(locale)

  const momentInstance = moment(...args)

  return momentInstance
}

export default woofMoment
