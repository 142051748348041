import "./style.css"
import {Plugin} from "@ckeditor/ckeditor5-core"
import {ButtonView, ContextualBalloon, clickOutsideHandler} from "@ckeditor/ckeditor5-ui"
import FormView from "./form-view"
import fontAwesomeIcons from "./icons.yml"

export default class FontAwesomePlugin extends Plugin {
  static get requires() {
    return [ContextualBalloon]
  }

  init() {
    this._defineSchema()
    this._defineConverters()

    const editor = this.editor

    // Create the balloon and the form view.
    this._balloon = this.editor.plugins.get(ContextualBalloon)
    this.formView = this._createFormView()

    editor.ui.componentFactory.add("fontAwesome", () => {
      const button = new ButtonView()

      button.label = "FontAwesome"
      button.tooltip = true
      button.withText = true

      // Show the UI on button click.
      this.listenTo(button, "execute", () => {
        this._showUI()
      })

      return button
    })
  }

  _defineSchema() {
    this.editor.model.schema.register("fontAwesomeIcon", {
      allowAttributes: ["class"],
      allowWhere: "$text",
      isInline: true,
      isObject: true
    })
    this.editor.model.schema.extend("$text", {
      allowIn: "fontAwesomeIcon"
    })
  }

  _defineConverters() {
    // Conversion from a FontAwesomeIcon into a FontAwesome icon like "<fontAwesomeIcon class="fa fa-check" />" => "<i class="fa fa-check" />"
    this.editor.conversion.for("downcast").elementToElement({
      model: "fontAwesomeIcon",
      view: (_modelItem, conversionApi, fontAwesomeIconModel) => {
        const {writer} = conversionApi
        const classes = fontAwesomeIconModel.item.getAttribute("class")

        return writer.createContainerElement("i", {class: classes})
      }
    })

    // FIXME: Dunno when this is actually used? It should probably take the icon-class and transfer it up the the FontAwesomeIcon model again?
    this.editor.conversion.for("upcast").elementToElement({
      view: {
        name: "i",
        classes: "fa"
      },
      model: "fontAwesomeIcon"
    })
  }

  _createFormView() {
    const editor = this.editor
    const formView = new FormView(editor.locale)

    // Execute the command after clicking the "Save" button.
    this.listenTo(formView, "submit", () => {
      const style = formView.styleInputView.fieldView.element.value
      const icon = formView.iconInputView.fieldView.element.value

      let foundIcon

      for (const faIcon in fontAwesomeIcons) {
        const faIconData = fontAwesomeIcons[icon]

        if (icon == faIcon && faIconData.styles.includes(style)) {
          foundIcon = [faIcon, faIconData]
          break
        }
      }

      if (!style) {
        alert(I18n.t("js.ckeditor.no_style_given"))
      } else if (!icon) {
        alert(I18n.t("js.ckeditor.no_icon_given"))
      } else if (!foundIcon) {
        alert(I18n.t("js.ckeditor.icon_not_found_in_list_of_font_awesome_icons"))
      } else {
        editor.model.change((writer) => {
          editor.model.insertContent(writer.createElement("fontAwesomeIcon", {class: `fa fa-${style} fa-${icon}`}))
        })
        setTimeout(() => editor.updateSourceElement(), 0.5)
        this._hideUI()
      }
    })

    // Hide the form view after clicking the "Cancel" button.
    this.listenTo(formView, "cancel", () => {
      this._hideUI()
    })

    // Hide the form view when clicking outside the balloon.
    clickOutsideHandler({
      emitter: formView,
      activator: () => this._balloon.visibleView === formView,
      contextElements: [this._balloon.view.element],
      callback: () => this._hideUI()
    })

    return formView
  }

  _showUI() {
    this._balloon.add({
      view: this.formView,
      position: this._getBalloonPositionData()
    })

    this.formView.focus()
  }

  _hideUI() {
    // Clear the input field values and reset the form.
    this.formView.styleInputView.fieldView.value = ""
    this.formView.iconInputView.fieldView.value = ""
    this.formView.element.reset()

    this._balloon.remove(this.formView)

    // Focus the editing view after inserting the icon so the user can start typing the content
    // right away and keep the editor focused.
    this.editor.editing.view.focus()
  }

  _getBalloonPositionData() {
    const view = this.editor.editing.view
    const viewDocument = view.document
    let target = null

    // Set a target position by converting view selection range to DOM
    target = () => view.domConverter.viewRangeToDom(viewDocument.selection.getFirstRange())

    return {
      target
    }
  }
}
