import Devise from "@kaspernj/api-maker/build/devise"
import {User} from "models"
export default async function usersUpdateSession(userId) {
  if (userId) {
    const currentUserWithRelationships = await User.ransack({id_eq: userId}).preload("user_roles.role").first()

    Devise.updateSession(currentUserWithRelationships, {scope: "user"})
    Devise.events().emit("onDeviseSignIn")
  } else {
    Devise.updateSession(null, {scope: "user"})
    Devise.events().emit("onDeviseSignOut")
  }
}
