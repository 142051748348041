import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import ElementInstance from "components/survey-step-elements/element-instance"
import memo from "set-state-compare/src/memo"
import OptionElement from "./option-element"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import React from "react"
import SurveyStepElementsContent from "components/survey-step-elements/content"

export default memo(shapeComponent(class ComponentsSurveyStepElementsPartialTypesImageOptions extends ShapeComponent {
  static propTypes = propTypesExact({
    className: PropTypes.string,
    elementInstance: PropTypes.instanceOf(ElementInstance).isRequired
  })

  render() {
    const {className, elementInstance} = this.props
    const {teamSurveyStepElement} = elementInstance.data

    return (
      <div className={classNames("components--survey-step-elements--partial-types--image-options", className)}>
        <SurveyStepElementsContent elementInstance={elementInstance} />
        <div className="grid grid-cols-2 grid-gap-10">
          {this.optionsInOrder().map((option, optionIndex) =>
            <div className="col-span-1" key={option.id()}>
              <OptionElement
                answer={teamSurveyStepElement?.answers()?.loaded()?.find((answer) => answer.surveyStepElementOptionId() == option.id())}
                elementInstance={elementInstance}
                option={option}
                optionIndex={optionIndex}
              />
            </div>
          )}
        </div>
      </div>
    )
  }

  optionsInOrder = () => this.p.elementInstance.data.element.options().loaded().sort((option1, option2) => option1.position() - option2.position())
}))
