import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import CommandsPool from "@kaspernj/api-maker/build/commands-pool"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import {SchoolClass} from "models"
import setLayout from "shared/set-layout"
import {View} from "react-native"

export default memo(shapeComponent(class ComponentsLayoutBase extends ShapeComponent {
  static defaultProps = {
    layout: "front",
    schoolClass: null
  }

  static propTypes = {
    headTitle: PropTypes.string,
    headerTitle: PropTypes.string,
    schoolClass: PropTypes.instanceOf(SchoolClass)
  }

  setup() {
    useMemo(() => {
      this.setGlobalRequestDataLayout()
    }, [])

    useMemo(() => {
      this.setDocumentTitle()
    }, [this.props.headTitle, this.props.headerTitle])

    useMemo(() => {
      this.setSchoolAndClass()
    }, [this.p.schoolClass?.id()])
  }

  componentWillUnmount() {
    CommandsPool.current().globalRequestData.school_id = undefined
    CommandsPool.current().globalRequestData.school_class_id = undefined
  }

  setDocumentTitle() {
    const headTitle = this.props.headTitle || this.props.headerTitle

    if (headTitle) {
      document.title = headTitle
    } else {
      document.title = "Wooftech"
    }
  }

  setGlobalRequestDataLayout() {
    if (!CommandsPool.current().globalRequestData) CommandsPool.current().globalRequestData = {}

    setLayout(this.p.layout)
  }

  setSchoolAndClass() {
    CommandsPool.current().globalRequestData.school_id = this.p.schoolClass?.schoolId()
    CommandsPool.current().globalRequestData.school_class_id = this.p.schoolClass?.id()
  }

  render() {
    const {children, className, dataSet, headTitle, headerTitle, layout, schoolClass, style, ...restProps} = this.props
    const actualStyle = Object.assign(
      {flex: 1},
      style
    )
    const actualDataSet = Object.assign(
      {class: className, component: "layout/base", layout},
      dataSet
    )

    return (
      <View dataSet={actualDataSet} style={actualStyle} {...restProps}>
        {children}
      </View>
    )
  }
}))
