import {digg} from "diggerize"
import {TrixFile} from "models"
import {v4 as uuidv4} from "uuid"
export default class UploadAdapter {
  constructor(editor, loader) {
    this.editor = editor
    this.loader = loader
  }

  // Embeds the image in the src-attribute and doesn't upload.
  async upload() {
    const file = await this.file()
    const dataUrl = await this.readFileAsDataUrl(file)

    return {
      default: dataUrl
    }
  }

  // Uploads the file to the server and returns a URL for the src-attribute.
  async uploadAsFile() {
    const file = await this.file()
    const formData = new FormData()

    formData.append("file", file)
    formData.append("key", uuidv4())
    formData.append("resource_id", this.editor.sourceElement.dataset.resourceId)
    formData.append("resource_type", this.editor.sourceElement.dataset.resourceType)

    const response = await TrixFile.upload(formData)

    return {
      default: digg(response, "attributes", "url")
    }
  }

  readFileAsDataUrl(file) {
    return new Promise((resolve) => {
      const fileReader = new FileReader()

      fileReader.onload = (e) => {
        resolve(e.target.result)
      }
      fileReader.readAsDataURL(file)
    })
  }

  file() {
    return new Promise((resolve) => {
      this.loader.file.then((result) => {
        resolve(result)
      })
    })
  }
}
